import React from 'react'
import styled from 'styled-components'
import { Divider, Button } from 'antd'
import {
  MembershipV3LightChecked,
  MembershipV3DarkChecked
} from '../icons/onboarding'
import background from '../../asserts/images/membership-banner.svg'
import { loadUser } from '../../utils/storage'

const Container = styled.div`
  .select-btn {
    letter-spacing: 2.8px;
    font-size: 14px;
    margin-top: 16px;
    width: 136px;
  }

  .is-current .select-btn {
    background: #264382 !important;
    color: #ffffff; 
  }

  .tag {
    position: absolute;
    color: #ffffff;
    background: #264382;
    font-size: 12px;
    padding: 5px 6px;
    border-radius: 4px;
    top: -22px;
    right: -5px;
    font-weight: 500;
    transform: scale(0.8);
  }

  .health-info {
    background: url(${background});
    color: #c0e9ff;
    padding: 35px;
    border-radius: 32px;
    max-height: 400px;
    letter-spacing: 1px;
    .tag {
      color: #264382;
      background: #ffffff;
      top: 32px;
      right: 28px;
    }
    .select-btn {
      background: #ffffff;
      margin-top: 0;
      margin-bottom: 16px;
    }
    .title {
      color #ffffff;
      margin-bottom: 16px;
      font-size: 24px;
      line-height: 1;
      font-weight: 700;
      span {
        font-size: 16px;
        font-weight: 400;
      }
    }
    .description {
      text-transform: uppercase;
      font-size: 12px;
      font-family: Heebo;
      font-weight: 500;
    }
    ul {
      list-style: none;
      margin-top: 20px;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      font-family: Heebo;
      font-weight: 400;
      color #ffffff;
      li {
        display: flex;
        padding: 5px 0;
        width: 220px;
        .anticon {
          margin-right: 6px;
        }
        &:nth-child(3n) {
          width: 500px;
        }
      }
    }
  }

  
  .tip {
    color: #264382;
    font-family: Lato;
    font-size: 12px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    margin-top: 15px;
  }
  .plan-container {
    margin-top: 46px;
    border-radius: 16px;
    background: #ffffff;
    color: #6D7D8C;
    font-family: Heebo;
    .add-service {
      margin-top: -50px;
      display: flex;
      li {
        width: ${(props) => (props.isInsightsUser ? '25%' : '20%')};
        padding: 24px;
        border: 1px solid transparent;
        border-right-color: rgba(0, 0, 0, 0.06);
      }
    }
    .plan-list {
      display: flex;
      li {
        width: ${(props) => (props.isInsightsUser ? '25%' : '20%')};
        padding: 24px;
        padding-top: 36px;
        border-right: 1px solid rgba(0, 0, 0, 0.06);
        font-family: 'Heebo';
        .name {
          position: relative;
          color: transparent;
          font-size: 24px;
          font-family: Gilroy;
          font-weight: 700;
          letter-spacing: 1px;
          span {
            -webkit-background-clip: text;
          }
        }
        .price {
          color: #313251;
          font-family: Gilroy;
          font-size: 16px;
          span {
            display: inline-block;
          }
        }
        .includes {
          padding-bottom: 12px;
          letter-spacing: 1px;
          font-weight: 400; 
          text-transform: uppercase;
          transform: scale(.9);
          margin-right: -20px;
          margin-left: -8px;
        }
      }
    }
    .add-items {
      color: #52606C;
      li {
        padding: 12px 0;
        width: 100%;
        border: none;
        display: flex;
        .anticon {
          margin-right: 14px;
        }
        &>div {
          padding-right: 6px;
        }
      }
      ul {
        li {
          padding: 0;
          &:before{
            content: '·';
            margin-right: 5px;
          }
        }
      }
    }
    ul {
      list-style: none;
      padding: 0;
      li.is-current{
        border: 1px solid #264382;
        border-radius: 16px;
        background: #E2F2FF;
      }
    }
    .plan-list li.is-current{
      border-bottom: none;
      border-radius: 16px 16px 0 0;
    }
    .add-service  li.is-current{
      border-top: none;
      border-radius: 0 0 16px 16px;
}
  }
`

const healthSpanList = [
  'Cognitive Health',
  'Balance',
  'Comprehensive Assessment Review',
  'Body Composition',
  'Muscle Fitness',
  'Exercise, Nutrition, Supplement, Sleep recommendations',
  'Bone Health',
  'Cardiovascular Fitness',
  '6 weeks of exercise programming & app access',
  'Movement Quality',
  'Advanced Blood Panel',
  '2 complimentary training/nutrition sessions'
]

const planList = [
  {
    name: 'GOLD',
    membership: 'gold',
    background: 'linear-gradient(264deg, #F9D423 -70.74%, #F89500 90.01%)',
    price: 50000,
    priceInfo: ['$10k', '$40,000'],
    hasBottom: true,
    extraItems: [
      <div>
        <strong>Monthly</strong> health and program update calls
      </div>,
      <div>
        Personalized training programs delivered regularly for at-home workouts
      </div>,
      <div>Premium App access</div>,
      <div>Wearable tech integration</div>,
      <div>
        Regular coordination with your support team as needed (physicians,
        wellness providers, etc)
      </div>,
      <div>
        Quarterly <strong>in person</strong> progress assessments
      </div>,
      <div>
        <strong>In person at Apeiron, in home,</strong> and{' '}
        <strong>virtual</strong> service sessions with our expert team of
        providers
      </div>,
      <div>
        <strong>125 sessions:</strong>
        <ul>
          <li>Training</li> <li>Yoga</li> <li>Nutrition</li> <li>Pilates</li>
          <li>Chiropractic</li> <li>Acupuncture</li> <li>Swimming</li>
          <li>Mindfulness</li> <li>Rehab/PT/injury evaluation</li>
          <li>Massage</li>
          <li>Stretching</li>
        </ul>
      </div>,
      <div>Weekly monitoring and recommendations from our experts</div>,
      <div>Continuous Glucose Monitoring (CGM) Program</div>,
      <div>Microbiome Analysis Program</div>,
      <div>Micronutrient Assessment</div>,
      <div>Sleep Consultation and Brain Map</div>,
      <div>Joint Health and Performance Recovery Module</div>
      // <div>Golf Module</div>
    ]
  },
  {
    name: 'SILVER',
    membership: 'silver',
    background: 'linear-gradient(226deg, #BDBBBE 3.21%, #9D9EA3 113.49%)',
    price: 35000,
    priceInfo: ['$10k', '$25,000'],
    hasBottom: true,
    extraItems: [
      <div>
        <strong>Monthly</strong> health and program update calls
      </div>,
      <div>
        Personalized training programs delivered regularly for at-home workouts
      </div>,
      <div>Premium App access</div>,
      <div>Wearable tech integration</div>,
      <div>
        Regular coordination with your support team as needed (physicians,
        wellness providers, etc)
      </div>,
      <div>
        Quarterly <strong>in person</strong> progress assessments
      </div>,
      <div>
        <strong>In person at Apeiron, in home,</strong> and{' '}
        <strong>virtual</strong> service sessions with our expert team of
        providers
      </div>,
      <div>
        <strong>100 sessions:</strong>{' '}
        <ul>
          <li>Training</li> <li>Yoga</li> <li>Nutrition</li> <li>Pilates</li>
          <li>Chiropractic</li> <li>Acupuncture</li> <li>Swimming</li>
          <li>Mindfulness</li> <li>Rehab/PT/injury evaluation</li>
          <li>Massage</li>
          <li>Stretching</li>
        </ul>
      </div>,
      <div>Weekly monitoring and recommendations from our experts</div>
    ]
  },
  {
    name: 'BRONZE',
    membership: 'bronze',
    background: 'linear-gradient(299deg, #DFA579 0%, #A2604E 70.04%)',
    price: 25000,
    priceInfo: ['$10k', '$15,000'],
    hasBottom: true,
    extraItems: [
      <div>
        <strong>Monthly</strong> health and program update calls
      </div>,
      <div>
        Personalized training programs delivered regularly for at-home workouts
      </div>,
      <div>Premium App access</div>,
      <div>Wearable tech integration</div>,
      <div>
        Regular coordination with your support team as needed (physicians,
        wellness providers, etc)
      </div>,
      <div>
        Quarterly <strong>in person</strong> progress assessments
      </div>,
      <div>
        <strong>In person at Apeiron, in home,</strong> and{' '}
        <strong>virtual</strong> service sessions with our expert team of
        providers
      </div>,
      <div>
        <strong>25 sessions:</strong>{' '}
        <ul>
          <li>Training</li> <li>Yoga</li> <li>Nutrition</li> <li>Pilates</li>
          <li>Chiropractic</li> <li>Acupuncture</li> <li>Swimming</li>
          <li>Mindfulness</li> <li>Rehab/PT/injury evaluation</li>
          <li>Massage</li>
          <li>Stretching</li>
        </ul>
      </div>
    ]
  },
  {
    name: 'REMOTE',
    membership: 'remote',
    background: 'linear-gradient(88deg, #89A8BA 4.45%, #9EB7C6 113.97%)',
    price: 20000,
    priceInfo: ['$10k', '$10,000'],
    hasBottom: true,
    extraItems: [
      <div>
        <strong>Quarterly</strong> health and program update calls
      </div>,
      <div>
        Personalized training programs delivered regularly for at-home workouts
      </div>,
      <div>
        Basic App Access <span style={{ color: 'transparent' }}>h</span>
      </div>,
      <div>Wearable tech integration</div>,
      <div>
        Quarterly coordination with your support team (physicians, wellness
        providers, etc)
        <span style={{ color: 'transparent', display: 'inline-block' }}>
          hidden
        </span>
      </div>,
      <div>
        Quarterly <strong>remote</strong> progress assessments
      </div>,
      <div>
        <strong>Virtual</strong> service sessions with our expert team of
        providers
        <span style={{ color: 'transparent', display: 'inline-block' }}>
          hidden hidden
        </span>
        <span style={{ color: 'transparent', display: 'inline-block' }}>
          hidden
        </span>
      </div>,
      <div>
        <strong>15 VIRTUAL sessions:</strong>
        <ul>
          <li>Training</li>
          <li>Yoga</li>
          <li>Nutrition</li>
          <li>Mat Pilates</li>
          <li>Mindfulness</li>
        </ul>{' '}
      </div>
    ]
  },
  {
    name: 'REMOTE LITE',
    membership: 'remote lite',
    background: 'linear-gradient(88deg, #9DA5AF 4.45%, #9DA5AF 113.97%)',
    price: 12500,
    priceInfo: ['$10k', '$2,500'],
    extraItems: [
      <div>
        <strong>Quarterly</strong> health and program update calls
      </div>,
      <div>
        Personalized training programs delivered regularly for at-home workouts
      </div>,
      <div>
        Basic App Access <span style={{ color: 'transparent' }}>h</span>
      </div>,
      <div>Wearable tech integration</div>,
      <div>
        Quarterly coordination with your support team (physicians, wellness
        providers, etc)
        <span style={{ color: 'transparent', display: 'inline-block' }}>
          hidden
        </span>
      </div>
    ]
  },
  {
    name: 'INSIGHTS',
    membership: 'insights',
    background: 'linear-gradient(88deg, #9DA5AF 4.45%, #9DA5AF 113.97%)',
    price: 15000,
    priceInfo: ['$10k', '$5000'],
    hasBottom: true,
    extraItems: [
      <div>
        <strong>Quarterly</strong> health and program update calls
      </div>,
      <div>
        Personalized training programs delivered regularly for at-home workouts
      </div>,
      <div>
        Basic App Access <span style={{ color: 'transparent' }}>h</span>
      </div>,
      <div>Wearable tech integration</div>,
      <div>
        Quarterly coordination with your support team (physicians, wellness
        providers, etc)
        <span style={{ color: 'transparent', display: 'inline-block' }}>
          hidden
        </span>
      </div>
    ]
  }
]

const amountFormat = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
})

export default function MemberShip(props) {
  const { current, onChange, selectable, price } = props
  const user = loadUser()
  const isInsightsUser = user.profile.membership === 'insights'
  const displayPlanList = isInsightsUser
    ? planList.filter(plan => ['gold', 'silver', 'bronze', 'insights'].includes(plan.membership))
    : planList.filter(plan => ['gold', 'silver', 'bronze', 'remote', 'remote lite'].includes(plan.membership))    

  return (
    <Container isInsightsUser={isInsightsUser}>
      <div
        className={`health-info ${current === 'evaluation2' ? 'is-current' : ''
          }`}
        onClick={() => selectable && onChange({ membership: 'evaluation2' })}
      >
        <div className="title">
          HEALTHSPAN ASSESSMENT <br />{' '}
          <span>
            {amountFormat.format(
              getPrice({ membership: 'evaluation2', price: 10000 })
            )}
          </span>{' '}
        </div>
        {!isInsightsUser && selectable && (
          <Button type="primary" className="select-btn" ghost size="large">
            {current === 'evaluation2' ? (
              <span>
                <GreenChecked /> SELECTED
              </span>
            ) : (
              'SELECT'
            )}
          </Button>
        )}
        {!selectable && current === 'evaluation2' && (
          <div className="tag">CURRENT</div>
        )}
        <div className="description">
          Advanced scientific testing of 350+ healthspan indicators with
          comprehensive analysis and personalized recommendations and <br />
          programming
        </div>

        <ul>
          {healthSpanList.map((item, index) => (
            <li key={`health-${index}`}>
              <MembershipV3LightChecked />
              <div>{item}</div>
            </li>
          ))}
        </ul>
      </div>
      <div className="tip">
        <strong>*All annual packages include Healthspan Assessment</strong>
      </div>

      <div className="plan-container">
        <ul className="plan-list">
          {displayPlanList.map((item, index) => (
            <li
              key={`list-${index}`}
              className={item.membership === current ? 'is-current' : ''}
            >
              <div className="name">
                <span style={{ backgroundImage: item.background }}>
                  {item.name}
                </span>
                {!selectable && item.membership === current && (
                  <div className="tag">CURRENT</div>
                )}
              </div>
              <div className="price">
                <span>{amountFormat.format(getPrice(item))}</span>{' '}
                {/* <span>
                  ({item.priceInfo[0]} + {item.priceInfo[1]})
                </span> */}
              </div>
              {selectable && (
                <Button
                  type="primary"
                  className="select-btn"
                  ghost
                  size="large"
                  onClick={() => selectable && onChange(item)}
                >
                  {item.membership === current ? (
                    <span>
                      <GreenChecked /> SELECTED
                    </span>
                  ) : (
                    'SELECT'
                  )}
                </Button>
              )}
              <Divider />
              <div className="includes">
                Includes Healthspan Assessment and adds:
              </div>
              <ul className="add-items">
                {item.extraItems.map((addItem, _index) => (
                  <li key={`extra-${index}-${_index}`}>
                    <MembershipV3DarkChecked />
                    {addItem}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
        <ul className="add-service">
          {displayPlanList.map((item, index) => (
            item.hasBottom ? (
              <li
                key={`add-${index}`}
                className={(item.membership === current )? 'is-current' : ''}
                onClick={() => selectable && onChange(item)}
              >
                *can add on service session packages if desired
              </li>
            ) : null
          ))}
        </ul>

      </div>
    </Container>
  )

  function getPrice(item) {
    const targetPrice = (price || []).find(
      (p) => p.membership === item.membership
    )

    return targetPrice
      ? targetPrice.discountedPrice || targetPrice.price
      : item.price
  }
}

function GreenChecked(props) {
  return (
    <svg
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.0625 4.4375L3.25 6.625L8.9375 0.9375"
        stroke="#05B800"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
