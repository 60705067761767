import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  .view-icon {
    position: absolute;
    right: ${(props) => (props.isSection ? '200px' : 0)};
    top: ${(props) =>
      props.isSection ? (props.type === 'hide' ? '45px' : '50px') : 0};
  }
  .just-title {
    border: none !important;
    padding: 0 !important;
    height: auto;
    color: #e2e5e9 !important;
  }
`

export default function HideWrap(props) {
  const [type, setType] = useState('show')
  const { name, title, isSection, syncStatus, status, view, id } = props
  useEffect(() => {
    if (status.includes(name)) {
      setType('hide')
    }
  }, [])
  return (
    <Wrapper isSection={isSection} type={type} id={id}>
      {view !== 'Preview' && (
        <span
          className="view-icon"
          style={props.viewIconStyle || {}}
          onClick={() => {
            if (type === 'show') {
              setType('hide')
              syncStatus(name, 'hide')
            } else {
              setType('show')
              syncStatus(name, 'show')
            }
          }}
        >
          <ViewIcon type={type} />
        </span>
      )}
      {type === 'show' && props.children}
      {type === 'hide' &&
        (isSection ? (
          <section
            style={{
              padding: '24px 200px'
            }}
          >
            <h1 className="section-title just-title">{title || name}</h1>
          </section>
        ) : (
          <div className="sub-title just-title">{title || name}</div>
        ))}
    </Wrapper>
  )
}

function ViewIcon(props) {
  const { type } = props
  return type === 'hide' ? (
    <svg
      width="22"
      height="14"
      viewBox="0 0 22 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 0C3.36701 0 1.07301 6.617 1.05201 6.684L0.946014 7L1.05101 7.316C1.07301 7.383 3.36701 14 11 14C18.633 14 20.927 7.383 20.948 7.316L21.054 7L20.949 6.684C20.927 6.617 18.633 0 11 0ZM11 11C8.79401 11 7.00001 9.206 7.00001 7C7.00001 4.794 8.79401 3 11 3C13.206 3 15 4.794 15 7C15 9.206 13.206 11 11 11Z"
        fill="#C5CCD3"
      />
      <path
        d="M11 5C9.916 5 9 5.916 9 7C9 8.084 9.916 9 11 9C12.084 9 13 8.084 13 7C13 5.916 12.084 5 11 5Z"
        fill="#C5CCD3"
      />
    </svg>
  ) : (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.073 10.194L2.212 6.33297C0.692 7.98997 0.116 9.64997 0.106 9.68397L0 9.99997L0.105 10.316C0.127 10.383 2.421 17 10.054 17C10.983 17 11.829 16.898 12.606 16.727L9.86 13.981C8.87128 13.9325 7.93595 13.518 7.23598 12.818C6.53601 12.118 6.12147 11.1827 6.073 10.194ZM10.054 2.99997C8.199 2.99997 6.679 3.40397 5.412 3.99797L1.707 0.292969L0.293 1.70697L18.293 19.707L19.707 18.293L16.409 14.995C19.047 13.042 19.988 10.358 20.002 10.316L20.107 9.99997L20.002 9.68397C19.98 9.61697 17.687 2.99997 10.054 2.99997ZM11.96 10.546C12.147 9.86897 11.988 9.10697 11.468 8.58597C10.948 8.06497 10.185 7.90697 9.508 8.09397L8 6.58597C8.61796 6.20595 9.32855 6.00322 10.054 5.99997C12.26 5.99997 14.054 7.79397 14.054 9.99997C14.051 10.7253 13.8479 11.4357 13.467 12.053L11.96 10.546Z"
        fill="#C5CCD3"
      />
    </svg>
  )
}
