import React from 'react'
import { Divider } from 'antd'
import _, { lowerCase } from 'lodash'
import styled from 'styled-components'
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons'
import Logarithmic from './logarithmic'
import { ChartContainer } from '../sections/aerobic'
import { BodyContainer } from './body'
import { handleMetricRanges } from '../wrapper'
import BodyChart from './bodyChart'
import { Growth } from '../../trends/customTooltip'
import BodyMale from '../../../asserts/images/body-male.svg'
import BodyFemale from '../../../asserts/images/body-female.svg'
import { RangeColors } from '../../../utils/constant'

const BodyCompValueContainer = styled.div`
  .metric-value-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .level {
      font-size: 16px;
    }
  }
  .ranges {
    margin-top: ${(props) => (props.smallSpace ? '20px' : '38px')} !important;
    .range-item .value {
      margin-top: -16px !important;
      span {
        font-size: 12px !important;
        font-family: Gilroy !important;
        font-weight: 600;
        margin-top: -12px !important;
      }
    }
  }
`

export default function BodyCompResult(props) {
  const {
    ui_version,
    bodyCompResults: {
      percentFatSeries,
      percentFatHistory,
      percent_fat,
      fat_mass_lbs,
      vat,
      lean_mass_lbs,
      height_inches,
      weight_lbs,
      fat_mass_index,
      lean_mass_index,
      target_lmi,
      target_fmi,
      target_almi,
      almi,
      AppendicularLMI,
      lmi_age_rank,
      fmi_age_rank,
      target_body_weight,
      target_fat_mass,
      target_lean_mass,
      total_body_fat_am
    },
    dataList,
    compareData,
    person,
    ageScopes
  } = props

  const bodyCompScore = {
    latest: dataList.find((data) => data.type === 'body_composition'),
    previous: compareData.find((data) => data.type === 'body_composition')
  }

  const scoreList = [
    {
      name: 'Healthspan Body Comp Score',
      previous:
        (bodyCompScore.previous.score_manual &&
          bodyCompScore.previous.score_manual.value) ||
        bodyCompScore.previous.score_auto,
      score:
        (bodyCompScore.latest.score_manual &&
          bodyCompScore.latest.score_manual.value) ||
        bodyCompScore.latest.score_auto,
      status: ['red', 'green']
    },
    {
      name: 'Percent Fat',
      previous: percent_fat.previous,
      score: percent_fat.latest,
      unit: '%',
      status: ['green', 'red']
    },
    {
      name: 'Fat Mass',
      previous: fat_mass_lbs.previous,
      score: fat_mass_lbs.latest,
      unit: 'lbs',
      status: ['green', 'red']
    },
    {
      name: 'VAT',
      previous: vat.previous,
      score: vat.latest,
      unit: (
        <span>
          cm <sup>2</sup>
        </span>
      ),
      status: ['green', 'red']
    },
    {
      name: 'Lean Mass',
      previous: lean_mass_lbs.previous,
      score: lean_mass_lbs.latest,
      unit: 'lbs',
      status: ['red', 'green']
    }
  ]

  const scoreListV2 = [
    {
      name: 'Healthspan Body Comp Score',
      previous:
        (bodyCompScore.previous.score_manual &&
          bodyCompScore.previous.score_manual.value) ||
        bodyCompScore.previous.score_auto,
      score:
        (bodyCompScore.latest.score_manual &&
          bodyCompScore.latest.score_manual.value) ||
        bodyCompScore.latest.score_auto,
      status: ['red', 'green']
    },
    {
      name: 'Percent Fat',
      status: ['green', 'red'],
      metricUnit: _.get(percent_fat, 'unit'),
      previous: _.get(percent_fat, 'previous'),
      unitKey: 'metricUnit',
      showScore: _.get(percent_fat, 'value'),
      scoreKey: 'showScore',
      score: _.get(total_body_fat_am, 'value'),
      unit: _.get(total_body_fat_am, 'unit'),
      ranges: _.get(total_body_fat_am, 'ranges'),
      level: _.get(total_body_fat_am, 'level'),
      color: _.get(total_body_fat_am, 'color'),
      rangeValueNumberPrefix: 'Age Rank: '
    },
    {
      name: 'Visceral Adipose Tissue',
      ...vat,
      score: vat.value,
      status: ['green', 'red'],
      showRangeArea: true
    },
    {
      name: 'Appendicular Lean Mass',
      previous: _.get(AppendicularLMI, 'previous'),
      metricUnit: _.get(AppendicularLMI, 'unit'),
      showScore: _.get(AppendicularLMI, 'value'),
      scoreKey: 'showScore',
      unitKey: 'metricUnit',
      score: _.get(almi, 'value'),
      unit: _.get(almi, 'unit'),
      status: ['red', 'green'],
      ranges: _.get(almi, 'ranges'),
      level: _.get(almi, 'level'),
      color: _.get(almi, 'color'),
      rangeValueNumberPrefix: 'Age Rank: '
    }
  ]

  const bodyItems = [
    {
      name: 'Height',
      value: height_inches,
      unit: '"'
    },
    {
      name: 'Weight',
      value: weight_lbs.latest,
      previous: weight_lbs.previous,
      unit: 'lbs',
      desired: target_body_weight
    },
    {
      name: 'Fat Mass Index',
      value: fat_mass_index,
      ageRank: fmi_age_rank,
      desired: target_fmi
    },
    {
      name: 'Lean Mass Index',
      value: lean_mass_index,
      ageRank: lmi_age_rank,
      desired: target_lmi
    },
    {
      name: 'Appendicular LMI',
      value: AppendicularLMI,
      ageRank: almi,
      desired: target_almi
    }
  ]

  const bodyItemsV2 = [
    {
      name: 'Height',
      value: height_inches,
      unit: '"'
    },
    {
      name: 'Weight',
      unit: 'lbs',
      desired: target_body_weight,
      up_color: 'neutral',
      extraStyle: {
        color: '#6A7EA9',
        background: '#F4F6F8'
      },
      ...weight_lbs
    },
    {
      name: 'Fat Mass',
      desired: target_fat_mass,
      up_color: 'red',
      ...fat_mass_lbs
    },
    {
      name: 'Lean Mass',
      desired: target_lean_mass,
      up_color: 'green',
      ...lean_mass_lbs
    }
  ]

  const getStatus = (item) => {
    let result = {
      color: '',
      status: ''
    }

    if (item.previous && item.score) {
      if (item.previous < item.score) {
        result.color = item.status[1]
        result.status = 'up'
      } else if (item.previous > item.score) {
        result.color = item.status[0]
        result.status = 'down'
      }
    }
    return result
  }

  const showScoreList = ui_version ? scoreListV2 : scoreList
  const showBodyItems = ui_version ? bodyItemsV2 : bodyItems

  return (
    <>
      <ChartContainer style={{ paddingBottom: 35 }}>
        <div style={{ marginTop: -20 }}>
          <div className="score-list">
            {showScoreList.map((item, index) => {
              const { color, status } = getStatus(item)
              const scoreKey = item.scoreKey || 'score'
              const unitKey = item.unitKey || 'unit'
              return (
                <div className="score-item" key={index}>
                  <div className="h-item">
                    <div>{item.name}</div>
                    {item.previous && <div>Previous – {item.previous}</div>}
                  </div>

                  <BodyCompValueContainer
                    smallSpace={item.name === 'Visceral Adipose Tissue'}
                  >
                    <div
                      className={`metric-value-container value ${
                        item.ranges ? '' : color
                      }`}
                    >
                      <div>
                        {_.isNumber(item[scoreKey]) ? (
                          <>
                            {item[scoreKey]}
                            {item[unitKey]}
                            {!item.ranges &&
                              status &&
                              (status === 'up' ? (
                                <CaretUpOutlined />
                              ) : (
                                <CaretDownOutlined />
                              ))}
                          </>
                        ) : (
                          '--'
                        )}
                      </div>
                      <div
                        className="level"
                        style={{ color: item.color && RangeColors[item.color] }}
                      >
                        {item.ranges && item.level}
                      </div>
                    </div>
                    {item.ranges && (
                      <RangeBar
                        {...item}
                        {...{ person, ageScopes }}
                        hideName
                        value={item.score}
                        hideValueNumber={
                          item.rangeValueNumberPrefix ? false : true
                        }
                        rangeValueNumberPrefix={item.rangeValueNumberPrefix}
                      />
                    )}
                  </BodyCompValueContainer>
                </div>
              )
            })}
          </div>
        </div>
        <div className="chart small">
          <Logarithmic
            {...{ percentFatSeries, percentFatHistory, person, ui_version }}
          />
        </div>
      </ChartContainer>
      <Divider />
      <ChartContainer>
        <div>
          <div className="score-list">
            {showBodyItems.map((item, index) => (
              <div
                key={index}
                className={`score-item ${ui_version ? 'v2' : ''}`}
              >
                <div className="body-item">
                  <div>
                    <div className="h-item">
                      {item.name}{' '}
                      {item.rate !== 0 && (
                        <span style={{ marginLeft: 10 }}>
                          <Growth
                            ratio={item.rate}
                            up_color={item.up_color}
                            extraStyle={item.extraStyle || {}}
                          />
                        </span>
                      )}
                    </div>
                    {!item.series && (
                      <div className="value">
                        {_.isNumber(item.value) ? (
                          <>
                            {item.value}
                            {item.unit}
                          </>
                        ) : (
                          '--'
                        )}
                      </div>
                    )}
                  </div>
                  <div className="r-item">
                    {item.previous && (
                      <span>
                        Previous – {item.previous} <br />
                      </span>
                    )}
                    {item.ageRank && (
                      <span>
                        Age-Ranking - {item.ageRank} <br />
                      </span>
                    )}
                    {item.desired && <span>Desired - {item.desired}</span>}
                  </div>
                </div>
                {item.series && (
                  <div style={{ margin: '-30px 0' }}>
                    <BodyChart series={item.series} name={item.name} />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div>
          <BodyStatus {...props} />
        </div>
      </ChartContainer>
    </>
  )
}

function BodyStatus(props) {
  const {
    ageScopes,
    person,
    ui_version,
    bodyCompResults: {
      arms_difference,
      legs_difference,
      left_arm_fat,
      trunk_fat,
      left_leg_fat,
      vat,
      right_leg_fat,
      right_arm_fat
    }
  } = props
  const ImageUrl = person.profile.gender === 'male' ? BodyMale : BodyFemale
  const vat_latest = ui_version ? vat.value : vat.latest
  return (
    <>
      <BodyContainer style={{ marginTop: ui_version ? 155 : 64, width: 480 }}>
        <div className="image-around" style={{ position: 'relative' }}>
          {/* half right body */}
          <div
            className="text-right"
            style={{ position: 'absolute', top: 5, left: -100 }}
          >
            <div className="label" style={{ position: 'relative', left: 60 }}>
              Right Arm % Fat
            </div>

            <div
              style={{
                border: 'none',
                cursor: 'default',
                position: 'relative',
                left: 60
              }}
              className="level"
            >
              {_.isNumber(right_arm_fat) ? right_arm_fat + '%' : '--'}
            </div>

            <div className="label" style={{ marginLeft: -10, marginTop: 65 }}>
              Trunk % Fat
            </div>
            <div
              style={{ marginLeft: -10, border: 'none', cursor: 'default' }}
              className="level"
            >
              {_.isNumber(left_arm_fat) ? trunk_fat + '%' : '--'}
            </div>

            <div
              className="label"
              style={{
                marginLeft: -50,
                marginTop: 95,
                position: 'relative',
                left: 60
              }}
            >
              Right Leg % Fat
            </div>
            <div
              style={{
                marginLeft: -50,
                border: 'none',
                cursor: 'default',
                position: 'relative',
                left: 60
              }}
              className="level"
            >
              {_.isNumber(right_leg_fat) ? right_leg_fat + '%' : '--'}
            </div>
          </div>

          <img
            src={ImageUrl}
            alt=""
            style={{ margin: '0 auto', height: 367 }}
          />

          {/* half left body */}
          <div style={{ position: 'absolute', right: -80, top: 5 }}>
            <div
              className="label"
              style={{ marginRight: -30, position: 'relative', left: -60 }}
            >
              Left Arm % Fat
            </div>
            <div
              style={{
                marginRight: -30,
                border: 'none',
                cursor: 'default',
                position: 'relative',
                left: -60
              }}
              className="level"
            >
              {_.isNumber(left_arm_fat) ? left_arm_fat + '%' : '--'}
            </div>

            <div className="label" style={{ marginRight: -15, marginTop: 65 }}>
              Vat Area
            </div>
            <div
              style={{ marginRight: -15, border: 'none', cursor: 'default' }}
              className="level"
            >
              {_.isNumber(vat_latest) ? (
                <>
                  {vat_latest} cm²
                  {/* cm<sup>2</sup> */}
                </>
              ) : (
                '--'
              )}
            </div>

            <div
              className="label"
              style={{
                marginRight: -50,
                marginTop: 95,
                position: 'relative',
                left: -60
              }}
            >
              Left Leg % Fat
            </div>
            <div
              style={{
                marginRight: -50,
                border: 'none',
                cursor: 'default',
                position: 'relative',
                left: -60
              }}
              className="level"
            >
              {_.isNumber(left_leg_fat) ? left_leg_fat + '%' : '--'}
            </div>
          </div>
        </div>
      </BodyContainer>
      <div>
        <RangeBar
          {...arms_difference}
          person={person}
          name="Arm Muscle Symmetry"
          ageScopes={ageScopes}
          showLevelOnNameRight
        />
        <RangeBar
          {...legs_difference}
          person={person}
          name="Leg Muscle Symmetry"
          ageScopes={ageScopes}
          showLevelOnNameRight
        />
      </div>
    </>
  )
}

const RangeBarContainer = styled.div`
  // width: 500px;
  color: #4c6072;
  .name {
    font-size: 15px;
  }
  .ranges {
    display: flex;
    margin-top: 60px;
    margin-bottom: 15px;
    .range-item {
      height: 6px;
      border: 1px solid #ffffff;
      position: relative;
      .value {
        margin-top: -6px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        span {
          font-size: 18px;
          color: #4c6072;
          font-family: Gilroy-Bold;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          margin-top: -25px;
          white-space: nowrap;
        }
      }
      .range-area {
        font-size: 12px;
        color: #4c6072;
        text-align: center;
        width: 100%;
        margin-top: 10px;
      }
      .range-level {
        font-size: 12px;
        text-align: center;
        color: #989ca2;
      }
    }
  }
`

export function RangeBar(props) {
  const {
    ranges,
    name,
    value,
    person,
    unit,
    ageScopes,
    level,
    color,
    hideValueNumber,
    hideName,
    showRangeArea,
    showLevelOnNameRight,
    showRangeLevel,
    showUnit = true,
    colorfulText,
    rangeValueNumberPrefix
  } = props

  let _ranges = handleMetricRanges(ranges, person, ageScopes)

  return (
    <RangeBarContainer>
      <div className="ranges" style={{ marginBottom: showRangeArea ? 30 : 15 }}>
        {_ranges.map((item, index) => (
          <div
            key={index}
            className={`${item.name} range-item`}
            style={{
              width: 100 / _ranges.length + '%',
              background: item.color || '#8A969F'
            }}
          >
            {level === item.name && (
              <>
                <ValueIcon
                  color={item.color || '#8A969F'}
                  style={getPointExtraStyle(value, item)}
                  {...{
                    value,
                    unit,
                    showUnit,
                    hideValueNumber,
                    colorfulText,
                    showRangeArea,
                    rangeValueNumberPrefix
                  }}
                />
              </>
            )}

            {showRangeArea && (
              <div
                className="range-area"
                style={{ color: colorfulText ? item.color : '#4c6072' }}
              >
                {formatRangeArea(item)}
              </div>
            )}
            {showRangeLevel && <div className="range-level">{item.name} </div>}
          </div>
        ))}
      </div>
      {!hideName && (
        <div
          className="name"
          style={
            showLevelOnNameRight
              ? { display: 'flex', justifyContent: 'space-between' }
              : {}
          }
        >
          <span>{name}</span>
          {showLevelOnNameRight && (
            <span style={props.levelStyle || { color: RangeColors[color] }}>
              {level}
            </span>
          )}
        </div>
      )}
    </RangeBarContainer>
  )

  function formatRangeArea(range) {
    if (range.max && range.min) {
      return `${range.min}-${range.max}`
    } else if (range.max) {
      return `< ${range.max}`
    } else {
      return `> ${range.min}`
    }
  }

  function getPointExtraStyle(value, range) {
    let left = 50
    let correctionOffset = -50
    if (range.max && range.min) {
      left = ((value - range.min) / (range.max - range.min)) * 100
    }
    if (Math.round(left) === 0) {
      correctionOffset = 0
    } else if (Math.round(left) === 100) {
      correctionOffset = -100
    }
    return {
      left: left + '%',
      transform: `translateX(${correctionOffset}%)`
    }
  }
}

export function RangeBarUnitFormat(raw) {
  const unit = lowerCase(raw)
  switch (unit) {
    case 'degrees':
      return '°'
    case 'percent':
      return '%'
    case 'n a':
      return ''
    default:
      return raw
  }
}

export function ValueIcon(props) {
  return (
    <div className="value" style={props.style || {}}>
      {!props.hideValueNumber && (
        <span style={{ color: props.colorfulText ? props.color : '#4c6072' }}>
          {props.rangeValueNumberPrefix ? props.rangeValueNumberPrefix : ''}
          {props.value}
          {props.showUnit && (props.unit ? RangeBarUnitFormat(props.unit) : '')}
        </span>
      )}
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="8"
          cy="7.99994"
          r="6"
          fill="white"
          stroke={props.color}
          strokeWidth="4"
        />
      </svg>
    </div>
  )
}
